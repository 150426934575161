import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { Title, Text, DividedSection, Button } from "gatsby-theme-material-foundry"
import { Container, Box, makeStyles, Grid, Divider } from "@material-ui/core"
import SEO from "../components/SEO/seo"
import { ThemeProvider } from "@material-ui/styles"
import { useTheme } from "@material-ui/styles"
import BackgroundImage from "gatsby-background-image"
import { AvatarCard } from "../components/event-cards"
import OverlayImage from "../components/OverlayImage"
import moment from "moment"
import EventList, { GenerateTags } from "../components/event-list"
import { navigate } from "@reach/router"
require("moment/locale/nb")

moment.locale("nb")

const useStyles = makeStyles(theme => ({
  hero: {
    color: "white",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },
  bigAvatar: {
    width: 100,
    height: 100,
  },
  heroTitle: {
    color: "white",
    textShadow: "2px 2px 4px #000000",
  },
  gridLayout: {
    flexDirection: "row-reverse", // 3
  },
}))

function NewsTemplate(props) {
  const { slug, personer, name, image, infrastruktur } = props.data.campus
  const arrangement = props.data.allArrangement.nodes
  const nyheter = props.data.allNyheter.nodes
  const theme = useTheme()

  const oyvind = props.data.oyvind
  const classes = useStyles()
  const urlCampus =
    props.location.search.split("=")[1] === "Forde"
      ? "Førde"
      : props.location.search.split("=")[1]
  const [hovered, setHovered] = React.useState(
    props.location.search ? urlCampus : ""
  )
  const onHover = data => setHovered(data)
  const backupEventImg = props.data.eventImg.childImageSharp.fluid

  return (
    <ThemeProvider theme={theme}>
      <Layout dark>
        <SEO postTitle={`Campus ${name}`} slug={`campus/${slug}`} />
        <BackgroundImage alt={name} fluid={image.childImageSharp.fluid}>
          <Box align="center" py={16}>
            <Container maxWidth="md">
              <Box pt={12}>
                <Title variant="h1" gutterBottom className={classes.heroTitle}>
                  {name}
                </Title>
              </Box>
            </Container>
          </Box>
        </BackgroundImage>

        <Container maxWidth="md">
          <Box py={4}>
            <Box pb={4}>
              <Title variant="h2" black gutterBottom id="kontakt-oss">
                Hva skjer {name === "Stord" ? "på" : "i"} {name}?
              </Title>
              <Text>
                Har du en idé du ønsker å utvikle? HVL Skape tilbyr
                veiledningstjenester for studenter som er i tidlig fase i
                idéutviklingsprosessen. Vår samlede kompetanse og erfaring med
                innovasjonsprosesser og oppstartsvirksomheter vil gi deg et godt
                grunnlag for å arbeide videre med din idé. I tillegg har vi
                mange kontakter innen en rekke bransjer og næringer som vi kan
                koble deg på hvor det er relevant.
              </Text>
            </Box>

            {infrastruktur &&
              infrastruktur.map((infra, i) => (
                <Grid
                  container
                  spacing={4}
                  key={infra.id}
                  className={i % 2 ? classes.gridLayout : ""}
                >
                  <Grid item xs={12} sm={6}>
                    {infra.infraImage && (
                      <Img
                        fluid={infra.infraImage.childImageSharp.fluid}
                        alt={infra.title}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Title variant="h4" black gutterBottom id="kontakt-oss">
                      {infra.title}
                    </Title>
                    <Text>{infra.text}</Text>
                    {(infra.title === "Marineholmen Makerspace") ? <Box mt={2}><Button color="primary" to="/campus/bergen/makerspace">Les mer og bli medlem</Button></Box>: ""}
                  </Grid>
                </Grid>
              ))}
          </Box>
        </Container>

        <DividedSection
          backgroundColor={theme.palette.secondary.light}
          className={classes.bottomsection}
        >
          <Box py={4} align="center">
            <Title variant="h3" black gutterBottom id="kontakt-oss">
              Kontakt team {name} for veiledning
            </Title>

            <Box pt={4}>
              <Grid container>
                <Grid item xs={6} sm={3}>
                  <AvatarCard
                    name={`${oyvind.fornavn} ${oyvind.etternavn}`}
                    role={oyvind.skapestilling ? oyvind.skapestilling : ""}
                    email={oyvind.emailaddress}
                    img={oyvind.avatar}
                    imageClasses={classes.bigAvatar}
                    link={oyvind.hvlprofilside ? oyvind.hvlprofilside : ""}
                  />
                </Grid>
                {personer &&
                  personer.map(person => (
                    <Grid item xs={6} sm={3}>
                      <AvatarCard
                        name={`${person.fornavn} ${person.etternavn}`}
                        role={person.skapestilling ? person.skapestilling : ""}
                        email={person.emailaddress}
                        img={person.avatar}
                        imageClasses={classes.bigAvatar}
                        link={person.hvlprofilside ? person.hvlprofilside : ""}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Box>
        </DividedSection>

        <Container>
          <Box mb={8}>
            <Grid container>
              <Grid item xs={12}>
                {arrangement.length > 0 && (
                  <Title variant="h4" black gutterBottom id="kontakt-oss">
                    Kommende arrangement
                  </Title>
                )}

                {arrangement &&
                  arrangement.map(e => {
                    const difference = moment(e.slutt).diff(e.start, "days")
                    return (
                      <Box key={e.id}>
                        <Box mb={4} mt={4}>
                          <Grid
                            container
                            key={e.id}
                            spacing={3}
                            onMouseEnter={() => onHover(e.id)}
                            onMouseLeave={() => onHover(false)}
                            onClick={() => navigate(`/arrangement/${e.slug}`)}
                            className={hovered === e.id ? classes.hovered : ""}
                          >
                            <Grid item xs="12" md="4">
                              <OverlayImage
                                image={e.cover}
                                backupImage={backupEventImg}
                                text={e.beskrivelse}
                                date={
                                  moment(e.slutt).diff(e.start, "days") > 0
                                    ? `${moment(e.start).format("D")}-${moment(
                                        e.slutt
                                      ).format("D MMMM YYYY")}`
                                    : moment(e.start).format(
                                        "dddd, D MMMM YYYY"
                                      )
                                }
                                multiDate={
                                  moment(e.slutt).diff(e.start, "days") > 0
                                }
                                onHover={onHover}
                                hovered={hovered}
                              />
                            </Grid>
                            <Grid item xs="12" md="8">
                              <Text variant="subheader">
                                {e.tags && (
                                  <GenerateTags tags={e.tags} color="black" />
                                )}
                              </Text>
                              <div>
                                <Title
                                  component={Link}
                                  to={`/arrangement/${e.slug}`}
                                  variant="h4"
                                  color="secondary"
                                >
                                  {e.tittel}
                                </Title>
                              </div>
                              <EventList
                                horizontal
                                date={
                                  difference > 0
                                    ? `${moment(e.start).format("D")}-${moment(
                                        e.slutt
                                      ).format("D MMMM YYYY")}`
                                    : moment(e.start).format(
                                        "dddd, D MMMM YYYY"
                                      )
                                }
                                time={`${moment(e.start).format(
                                  "HH:mm"
                                )}-${moment(e.slutt).format("HH:mm")}`}
                                location={e.adresse}
                                food={e.mat}
                                maxAttendees={
                                  e.maksdeltakere
                                    ? `Maks ${e.maksdeltakere} stk`
                                    : ""
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Divider />
                      </Box>
                    )
                  })}
                {arrangement.length < 1 && nyheter.length < 1 && (
                  <Container align="center" maxWidth="sm">
                    <Box pt={8}>
                      <Text black variant="h5" component="p">
                        Det er dessverre ikke noen arrangement eller nyheter i{" "}
                        {name} for tiden. Kontakt oss for mer info.
                      </Text>
                    </Box>
                  </Container>
                )}
              </Grid>
              <Grid item xs={12}>
                {nyheter.length > 0 && (
                  <Title variant="h4" black gutterBottom id="kontakt-oss">
                    Nyheter
                  </Title>
                )}

                {nyheter &&
                  nyheter.map(n => (
                    <Grid item key={n.id}>
                      <Box my={2}>
                        <Grid container spacing={2}>
                          <Grid item sm={4}>
                            <img
                              src={`${n.coverUrl}&sz=w500`}
                              alt={`${n.tittel}`}
                              style={{
                                width: "100%",
                                maxHeight: "200px",
                                objectFit: "cover",
                                objectPosition: "100% 50%",
                              }}
                            />
                          </Grid>
                          <Grid item sm={8}>
                            <Title
                              variant="h6"
                              component={Link}
                              to={`nyheter/${n.slug}`}
                              secondary
                            >
                              {n.tittel}
                            </Title>
                            <Box mb={2}>
                              <Text black className={classes.subtitle}>
                                {n.dato}
                              </Text>
                            </Box>
                            <Text>
                              {n.blurb}...
                              <Link
                                className={classes.morelink}
                                to={`nyheter/${n.slug}`}
                              >
                                les mer{" "}
                              </Link>
                            </Text>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export default NewsTemplate

export const ItemPageQuery = graphql`
  query SingleCampus($itemId: String!, $itemName: String, $now: Date) {
    campus(id: { eq: $itemId }) {
      personer {
        avatar
        fornavn
        etternavn
        navn
        stilling
        skapestilling
        hvlprofilside
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      address
      name
      id
      infrastruktur {
        title
        text
        infraImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    oyvind: folk(email: { eq: "Oyvind.Midtbo.Berge@hvl.no" }) {
      avatar
      fornavn
      etternavn
      navn
      stilling
      skapestilling
      hvlprofilside
    }
    allNyheter(
      sort: { order: DESC, fields: dato }
      filter: { campus: { eq: $itemName } }
    ) {
      nodes {
        tittel
        slug
        tekst
        blurb
        coverUrl
        dato(locale: "nb", formatString: "dddd, Do MMMM YYYY")
        forfatter
        campus
      }
    }
    allArrangement(
      sort: { order: ASC, fields: start }
      filter: { start: { gte: $now }, campus: { eq: $itemName } }
    ) {
      nodes {
        start
        slutt
        slug
        registreringslenke
        programUrl
        mat
        maksdeltakere
        facebookevent
        cover
        id
        campus
        beskrivelse
        adresse
        tittel
        tags
      }
    }
    eventImg: file(relativePath: { eq: "event_cover.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
